<template>
  <div :id="divName"></div>
</template>
<script>
import Player from "@vimeo/player";
export default {
  data() {
    return {
      divName: "divFrame",
    };
  },
  props: {
    options: {
      type: Object,
    },
  },
  created() {
    this.divName = this.divName + "_" + this.options.id;
  },
  mounted() {
    let p = new Player(this.divName, this.options);
    p.setCurrentTime(0);
  },
};
</script>
<style lang="scss" scoped>
</style>
