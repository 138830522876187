<template>
  <header class="header" :class="{ 'header-light': white }" ref="header">
    <div class="mycontainer">
      <div class="header__body">
        <router-link to="/" custom v-slot="{ navigate }" v-if="!isBurgerActive">
          <div class="header__logo" @click="navigate"></div>
        </router-link>
        <div v-if="isBurgerActive" class="header__logo-black">
          <img src="./../assets/images/logo_black.png" alt="" />
        </div>
        <div v-if="!isBurgerActive" class="header__right">
          <div class="header__burger" ref="burger" @click="open()">
            <span></span>
          </div>
          <router-link to="/#contacts" class="header__text"
            >Go innocean</router-link
          >
        </div>
        <div v-if="isBurgerActive" class="header__right header__right--black">
          Меню
          <div class="close" @click="close()"></div>
        </div>

        <nav class="header__menu" ref="menu">
          <ul class="header__list">
            <li class="header__link">
              <router-link to="/" class="link" @click.native="close()"
                >Главная</router-link
              >
            </li>
            <li class="header__link">
              <router-link
                tag="a"
                to="/archive"
                class="link"
                href=""
                @click.native="close()"
                >Архив</router-link
              >
            </li>
            <li class="header__link">
              <router-link class="link" to="/#team" @click.native="close()"
                >Команда</router-link
              >
            </li>
            <li class="header__link">
              <router-link class="link" to="/#contacts" @click.native="close()"
                >Контакты</router-link
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      isBurgerActive: false,
      windowTop: 0,
      white: true,
    };
  },
  methods: {
    handleScroll() {
      if (this.$route.name == "Home") {
        if (window.scrollY >= window.innerHeight) {
          this.white = true;
        } else {
          this.white = false;
        }
      }
    },

    createListener(pageName) {
      if (pageName == "Home") {
        this.white = false;
        window.addEventListener("scroll", this.handleScroll, { passive: true });
      } else {
        window.removeEventListener("scroll", this.handleScroll);
        this.white = true;
      }
    },

    open() {
      this.$refs.menu.classList.toggle("active");
      this.$refs.burger.classList.toggle("active");
      this.isBurgerActive = !this.isBurgerActive;
    },

    close() {
      this.$refs.menu.classList.toggle("active");
      this.isBurgerActive = !this.isBurgerActive;
    },
  },

  watch: {
    $route(to) {
      this.createListener(to.name);
    },
  },

  mounted() {
    this.createListener(this.$route.name);
  },
};
</script>
<style lang="scss" scoped>
.header {
  height: 100px;
  // max-width: 1620px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 50;
  transition: background-color 0.2s linear;
  @media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    display: none;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    display: none;
  }

  @media screen and (min-width: 0\0) {
    background-color: #ffffff;
  }

  &.header-light {
    background-color: white;
  }

  @media (max-width: 1280px) {
    height: 75px;
  }

  &__body {
    padding: 0 30px;
    // max-width: 1620px;
    height: 100px;
    margin: 0 auto;
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;

    @media (max-width: 1280px) {
      height: 75px;
    }

    @media (max-width: 767px) {
      padding: 0 10px;
    }
  }

  &__logo-black {
    display: flex;
    width: 181px;
    height: 70px;
    z-index: 3;
    cursor: pointer;
    transition: opacity 0.2s linear;

    &:hover {
      opacity: 0.1;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media (max-width: 1280px) {
      width: 134px;
      height: 52px;
    }

    @media (max-width: 767px) {
      width: 144px;
      height: 55px;
    }
  }

  &__logo {
    display: flex;
    width: 181px;
    height: 70px;
    color: white;
    z-index: 3;
    cursor: pointer;
    transition: opacity 0.2s linear;

    background: url("./../assets/images/innocean_logo_2x.png") no-repeat center;
    background-size: contain;

    &:hover {
      opacity: 0.7;
    }

    @media (max-width: 1280px) {
      background: url("./../assets/images/logo_m.png") no-repeat center;
      width: 134px;
      height: 52px;
    }

    @media (max-width: 767px) {
      background: url("./../assets/images/logo_s.png") no-repeat center;
      width: 144px;
      height: 55px;
    }
  }

  &__burger {
    display: flex;
    margin-right: 117px;
    &:hover {
      cursor: pointer;
    }
    display: block;
    position: relative;

    width: 54px;
    height: 20px;

    @media (max-width: 1280px) {
      margin-right: 27px;
      width: 40px;
      height: 15px;
    }

    @media (max-width: 767px) {
      order: 2;
      margin-right: 0;
      margin-top: 9px;
    }

    z-index: 5;
    &::before,
    &::after {
      content: "";
      background-color: #000000;
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;

      transition: all 0.3s ease 0s;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    span {
      position: absolute;
      background-color: #000000;
      top: 9px;
      left: 0;
      width: 100%;
      height: 2px;

      transition: all 0.3s ease 0s;

      @media (max-width: 1280px) {
        top: 7px;
      }
    }

    &.active::before {
      top: 9px;
    }

    &.active::after {
      top: 9px;
    }

    &.active span {
      transform: scale(0);
    }
  }

  &__list {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    width: 32.5%;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    position: relative;
    top: 22%;
    z-index: 3;

    @media (max-width: 1280px) {
      top: 12%;
    }

    @media (max-width: 991px) {
      width: 33%;
    }

    @media (max-width: 767px) {
      top: 0;
      width: 48%;
    }
  }

  &__link {
    color: #1f1e1d;
    text-decoration: none;
    vertical-align: center;
    text-transform: uppercase;
    position: relative;
    font-size: 2rem;

    transition: color 0.3s linear;
    cursor: pointer;

    &:hover {
      color: #ffffff;
    }

    @media (max-width: 767px) {
      margin-bottom: 9px;
      font-size: 3rem;
    }
  }

  &__menu {
    position: absolute;
    top: -200%;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #f0582a;
    z-index: 1;
    padding-top: 100px;
    transition: all 0.5s ease 0s;

    &.active {
      top: 0;
      height: 100vh;
    }
  }
}

.header__right {
  position: relative;
  min-width: 32%;
  display: flex;
  align-items: center;
  z-index: 3;

  @media screen and (min-width: 0\0) {
    margin-left: 32%;
    min-width: 0px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-end;
    min-width: 48%;
  }

  &--black {
    color: #1f1e1d;
    font-size: 2rem;

    @media (max-width: 767px) {
      align-items: flex-start;
      font-size: 3rem;
    }
  }
}

.header__text {
  display: flex;
  font-size: 2rem;
  color: #f0582a;
  cursor: pointer;
  transition: color 0.2s linear;

  &:hover {
    color: #000000;
  }

  @media (max-width: 1280px) {
    font-size: 17px;
  }

  @media (max-width: 767px) {
    order: 1;
    font-size: 12px;
  }
}

a.link:active,
a.link {
  transition: color 0.2s linear;
  color: #1f1e1d;
}
a.link:hover {
  color: #ffffff;
}

.close {
  position: absolute;
  left: 250px;
  top: 0;
  width: 32px;
  height: 32px;

  cursor: pointer;

  @media (max-width: 1200px) {
    left: 200px;
  }

  @media (max-width: 1100px) {
    left: 150px;
  }

  @media (max-width: 767px) {
    left: 100px;
  }
}

.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 32px;
  width: 3px;
  background-color: #000000;
  transition: background-color 0.2s linear;

  @media (max-width: 1200px) {
    height: 25px;
  }

  @media (max-width: 1100px) {
    height: 23px;
  }

  @media (max-width: 767px) {
    height: 20px;
    width: 2px;
  }
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
</style>
