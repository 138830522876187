<template>
  <section class="wrapper" id="team">
    <div class="team" ref="teamBlock">
      <div class="team__inner" ref="teamScroll">
        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
        <div class="team__col">
          <div class="team__spacer"></div>
          <div class="item">
            <div class="item__top">
              <img src="../assets/images/team/hyun_woong-min.jpg" alt="" />
            </div>
            <div class="item__name">Hyunwoong Kim</div>
            <div class="item__description">CEO</div>
          </div>
        </div>
        <div class="team__col">
          <div class="item">
            <div class="item__top">
              <img src="../assets/images/team/yuliya_koroleva-min.jpg" alt="" />
            </div>
            <div class="item__name">Юлия Королева</div>
            <div class="item__description">Managing Director</div>
          </div>
        </div>
        <div class="team__col">
          <div class="team__spacer"></div>
          <div class="item">
            <div class="item__top">
              <img
                src="../assets/images/team/stanislav_safronov-min.jpg"
                alt=""
              />
            </div>
            <div class="item__name">Станислав Сафронов</div>
            <div class="item__description">Clients Service Director</div>
          </div>
        </div>
        <div class="team__col">
          <div class="item">
            <div class="item__top">
              <img
                src="../assets/images/team/kristina_pryanichnikova-min.jpg"
                alt=""
              />
            </div>
            <div class="item__name">Кристина Пряничникова</div>
            <div class="item__description">New Business Director</div>
          </div>
        </div>
        <div class="team__col">
          <div class="team__spacer"></div>
          <div class="item">
            <div class="item__top">
              <img
                src="../assets/images/team/yaroslav_zelenskiy-min.jpg"
                alt=""
              />
            </div>
            <div class="item__name">Ярослав Зеленский</div>
            <div class="item__description">Creative Director</div>
          </div>
        </div>
        <div class="team__col">
          <div class="item">
            <div class="item__top">
              <img
                src="../assets/images/team/nikita_krechetov-min.jpg"
                alt=""
              />
            </div>
            <div class="item__name">Никита Кречетов</div>
            <div class="item__description">Digital Head</div>
          </div>
        </div>
        <div class="team__col">
          <div class="team__spacer"></div>
          <div class="item">
            <div class="item__top">
              <img
                src="../assets/images/team/natalia_katcharava-min.jpg"
                alt=""
              />
            </div>
            <div class="item__name">Наталья Качарава</div>
            <div class="item__description">Strategy Director</div>
          </div>
        </div>
        <div class="team__col">
          <div class="item">
            <div class="item__top">
              <img src="../assets/images/team/karen_akopyan-min.jpg" alt="" />
            </div>
            <div class="item__name">Карен Акопян</div>
            <div class="item__description">Media Director</div>
          </div>
        </div>
        <div class="team__col">
          <div class="team__spacer"></div>
          <div class="item">
            <div class="item__top">
              <img
                src="../assets/images/team/anastasiya_smolygina-min.jpg"
                alt=""
              />
            </div>
            <div class="item__name">Анастасия Смолягина</div>
            <div class="item__description">Account Group Head</div>
          </div>
        </div>
        <div class="team__col">
          <div class="item">
            <div class="item__top">
              <img src="../assets/images/team/jiyong_song-min.jpg" alt="" />
            </div>
            <div class="item__name">Jiyong Song</div>
            <div class="item__description">NB Team Head</div>
          </div>
        </div>
        <div class="team__col">
          <div class="team__spacer">
            <h2 class="team__text">Наши главы <br />Наши руки</h2>
          </div>
          <div class="item">
            <div class="item__top">
              <img src="../assets/images/team/inna_krylkova-min.jpg" alt="" />
            </div>
            <div class="item__name">Инна Крылкова</div>
            <div class="item__description">Head of Finance</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import gsap from "../plugins/gsap.js";
import ScrollTrigger from "gsap/ScrollTrigger";
export default {
  data() {
    return {};
  },
  methods: {
    horizontalScroll() {
      let horizontalSection = this.$refs.teamScroll;
      let block = this.$refs.teamBlock;
      let padding = 40;
      if (window.innerWidth > 992) {
        padding = 40;
      } else if (window.innerWidth < 991 && window.innerWidth > 767) {
        padding = 20;
      } else if (window.innerWidth < 767) {
        padding = 10;
      }
      gsap.to(horizontalSection, {
        scrollTrigger: {
          scrub: true,
          trigger: block,
          pin: true,
          anticipatePin: 1,
          start: "-50px top",
          end: () => `+=${horizontalSection.offsetWidth}`,
          invalidateOnRefresh: true,
        },
        x: () =>
          -horizontalSection.offsetWidth + block.offsetWidth - padding * 2,
        ease: "none",
      });
    },
  },
  mounted() {
    this.horizontalScroll();
  },

  updated() {
    ScrollTrigger.refresh(true);
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  background-color: #ffffff;
  @media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    display: none;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    display: none;
  }
}
.team {
  margin: 0 auto;
  max-width: 1620px;
  padding: 0 40px;
  display: flex;

  align-items: center;
  min-height: 100vh;
  overflow-x: hidden;

  @media screen and (min-width: 0\0) {
    overflow-x: scroll;
    min-height: 90vh;
  }

  @media (max-width: 991px) {
    padding: 0 20px;
  }

  @media (max-width: 767px) {
    padding: 0 10px;
    // min-height: 100vh;
    // overflow-x: hidden;
  }

  &__inner {
    display: flex;
  }

  &__col {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-right: 40px;

      @media (max-width: 991px) {
        margin-right: 20px;
      }
      @media (max-width: 767px) {
        margin-right: 10px;
      }
    }
  }

  &__spacer {
    width: 100%;
    height: 34vmin;

    @media (max-height: 1400px) and (min-height: 1100px) {
      height: 25vmin;
    }

    @media (max-width: 991px) {
      height: 33vmin;
    }

    @media (max-width: 767px) {
      height: 49vw;
    }
  }

  &__text {
    font-size: 2rem;
    line-height: 1.2;
    color: #1f1e1d;
    @media (max-width: 767px) {
      width: 53%;
      margin-left: 47%;
    }
  }
}

.item {
  width: 30vmin;
  display: flex;
  flex-direction: column;

  @media (max-width: 1050px) {
    width: 28vmin;
  }

  @media (max-height: 1400px) and (min-height: 1100px) {
    width: 21vmin;
  }

  @media (max-width: 991px) {
    width: 30vmin;
  }

  @media (max-width: 767px) {
    width: 46vw;
  }

  &__top {
    width: 100%;
    height: 30vmin;
    background-color: black;

    @media (max-height: 1400px) and (min-height: 1100px) {
      height: 21vmin;
    }
    @media (max-width: 991px) {
      height: 30vmin;
    }

    @media (max-width: 767px) {
      height: 46vw;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    margin-top: 3.7vmin;
    font-size: 2rem;

    line-height: 1.2;
    color: #010001;
    @media (max-width: 1366px) {
      font-size: 1.5rem;
    }

    @media (max-width: 1280px) {
      font-size: 1.4rem;
    }

    @media (max-width: 991px) {
      font-size: 2rem;
    }

    @media (max-width: 767px) {
      margin-top: 7px;
    }
  }

  &__description {
    margin-top: 0.925vmin;
    font-family: "Innodaoom-light";
    font-size: 2rem;

    line-height: 1.2;
    color: #010001;
    text-transform: none;

    @media (max-width: 1366px) {
      font-size: 1.5rem;
    }

    @media (max-width: 1280px) {
      font-size: 1.4rem;
    }

    @media (max-width: 991px) {
      font-size: 2rem;
    }

    @media (max-width: 767px) {
      margin-top: 7px;
    }
  }
}
</style>
