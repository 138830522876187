<template>
  <section class="contacts" id="contacts">
    <div class="contacts__inner">
      <hr class="contacts__hr" />
      <div class="contacts__content">
        <div class="filter">
          <iframe
            class="bottom__map"
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A5e6b7f6f23ee665640dcbd050e0b53cf3489893ec391f9af5c94e7095b8c5412&amp;source=constructor"
            width="394"
            height="720"
            frameborder="0"
          ></iframe>

          <div class="agancy-adress">
            Рекламное агенство Innocean:<br />
            <span>Пресненская наб. 10, Москва, Россия</span>
          </div>
        </div>

        <div class="bottom__right">
          <div class="row one">
            <div class="item empty"></div>
            <div class="item pos empty">
              <h2 class="header__title">contacts</h2>
            </div>
            <div class="item fr-extra">
              <h2 class="header__title">contacts</h2>
              <div class="item__title">Frankfurt RHQ</div>
              <div class="item__adress">
                Schwedlerstr. 6, 60314 Frankfurt am Main Germany<br />
                Tel. +49 69 941759 100
              </div>
            </div>
            <div class="head">
              <h2 class="header__title">nb head</h2>
              <img
                src="../assets/images/team/kristina_pryanichnikova-min.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="row two">
            <div class="item empty"></div>
            <div class="item">
              <div class="item__title">Moscow</div>
              <div class="item__adress">
                Presnenskaya Emb., 10, Block B, Naberezhnaya Tower, 9th Floor<br />
                Tel. +7 495 514 0121
              </div>
            </div>
            <div class="item">
              <div class="item__name">Кристина Пряничникова</div>
              <div class="item__contacts">
                8(916)202-09-65<br />
                +7 495 514 0121 (ext.127)<br />
                kpryanichnikova@innocean.ru
              </div>
            </div>
          </div>
          <div class="row">
            <div class="item fr">
              <div class="item__title">Frankfurt RHQ</div>
              <div class="item__adress">
                Schwedlerstr. 6, 60314 Frankfurt am Main Germany<br />
                Tel. +49 69 941759 100
              </div>
            </div>
            <div class="item">
              <div class="item__title">Seoul HQ</div>
              <div class="item__adress">
                Landmark Tower 20F, 837-36 <br />Tel. +82.2.2016.2300
              </div>
            </div>
            <div class="item">
              <div class="item__title">Huntington Beach RHQ</div>
              <div class="item__adress">
                180 5th Street, Suite 200, Huntington Beach, CA
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.contacts {
  max-height: 83vh;
  max-width: 1620px;
  margin: 0 auto;
  margin-top: 100px;
  background-color: #ffffff;

  @media (max-width: 1366px) {
    margin-top: 0;
  }

  @media (max-width: 1280px) {
    margin-top: 50px;
  }

  @media (max-width: 991px) {
    margin-top: 0;
  }

  @media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    display: none;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    display: none;
  }
  @media (max-width: 767px) {
    max-height: 100vh;
  }

  &__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__hr {
    margin-top: 50px;
    border: none;
    width: 100%;
    height: 2px;
    background-color: #1f1e1d;
    @media (max-width: 991px) {
      margin-top: 40px;
    }
  }

  &__content {
    padding: 40px 40px 40px 0;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1280px) {
      padding: 20px 20px 20px 0;
    }

    @media (max-width: 991px) {
      padding: 20px 0 0 0;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      padding: 10px 0 0 0;
    }
  }
}
.header {
  &__title {
    position: absolute;
    top: -93px;
    left: 0;
    font-size: 2rem;
    line-height: 1.2;
    color: #010001;

    @media (max-width: 1280px) {
      top: -70px;
    }

    @media (max-width: 991px) {
      top: -47px;
    }

    @media (max-width: 767px) {
      top: -38px;
    }
  }
}
.bottom {
  &__map {
    width: 100%;
    max-height: 69.4vh;
    @media screen and (min-width: 0\0) {
      display: none;
    }
    @media (max-width: 1366px) {
      max-height: 65vh;
    }
    @media (max-width: 991px) {
      max-height: 53vh;
    }

    @media (max-width: 767px) {
      max-height: 34vh;
      margin-right: 0;
    }
  }

  &__right {
    width: 72.4%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 991px) {
      padding: 0 20px;
      width: 50%;
    }

    @media (max-width: 767px) {
      padding: 0 10px;
      width: 100%;
      order: 1;
    }
  }

  &__row {
    display: flex;
    width: 100%;
  }
}

.filter {
  width: 24.9%;
  filter: grayscale(100%);

  @media (max-width: 991px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    order: 2;
    width: 100%;
  }

  @media screen and (min-width: 0\0) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.head {
  width: 31%;
  height: auto;
  position: relative;
  @media (max-width: 991px) {
    width: 44.6%;
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
  img {
    display: block;
    width: 100%;

    @media (max-width: 1366px) {
      width: 80%;
    }
    @media (max-width: 1280px) {
      width: 70%;
    }
    @media (max-width: 1100px) {
      width: 100%;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  &.two {
    padding-bottom: 10px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  width: 31%;
  @media screen and (min-width: 0\0) {
    margin-bottom: 15px;
  }
  @media (max-width: 991px) {
    width: 44.6%;
    min-height: 14vh;
  }
  @media (max-width: 767px) {
    min-height: 110px;
  }
  &.pos {
    position: relative;
  }

  &.empty {
    @media (max-width: 991px) {
      display: none;
    }
  }

  &.fr {
    @media (max-width: 991px) {
      display: none;
    }
  }

  &.fr-extra {
    display: none;
    position: relative;
    @media (max-width: 991px) {
      display: flex;
    }
  }

  &__title {
    font-size: 1.33rem;
    line-height: 1.2;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 1;
    }
  }

  &__adress {
    font-family: "Innodaoom-light";
    font-size: 1.33rem;
    line-height: 1.2;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 1;
    }
  }

  &__contacts {
    font-family: "Innodaoom-light";
    font-size: 1.33rem;
    line-height: 1.2;
    text-transform: none;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 1;
    }
  }

  &__name {
    font-size: 1.33rem;
    line-height: 1.2;
    text-transform: none;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 1;
    }
  }
}

.agancy-adress {
  display: none;
  font-size: 1.33rem;
  line-height: 1.2;
  span {
    font-family: "Innodaoom-light";
    font-size: 1.33rem;
    line-height: 1.2;
    text-transform: none;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 1;
    }
  }

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 1;
  }
  @media screen and (min-width: 0\0) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
