<template>
  <div class="section-wrapper">
    <section class="cloud" ref="scrollSection">
      <div class="top-block">
        <div class="title-block">
          <div class="text"></div>
          <h2 class="text cloud__title">Наша экспертиза - Поиск решений</h2>
        </div>
        <div class="title-block row3">
          <div class="title-block__item"></div>
          <div class="title-block__item">
            <h2 class="cloud__title">
              Наша экспертиза - <br />
              Поиск решений
            </h2>
          </div>
        </div>
        <div class="cloud__inner">
          <div class="cloud__item one">
            <div class="wrapper">
              <div class="cloud__item-inner" ref="leftCol">
                <div
                  v-for="(item, index) in [...tags1, ...tags1]"
                  :key="index"
                  class="cloud__text"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="cloud__item two" ref="item2">
            <div class="wrapper">
              <div class="cloud__item-inner" ref="centerCol">
                <div
                  v-for="(item, index) in [...tags2, ...tags2]"
                  :key="index"
                  class="cloud__text"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="cloud__item three">
            <div class="cloud__item-inner right" ref="rightColWrapper">
              <div class="cloud__text cloud__text--right" ref="rightCol">
                {{ tags3 + " " + tags3 + " " }}
              </div>
            </div>
          </div>
          <div class="cloud__text-block">
            <h2 class="cloud__title">
              Наша экспертиза - <br />
              Поиск решений
            </h2>
            <h2 class="cloud__title">
              В любых <br />
              Направлениях
            </h2>
          </div>
        </div>
        <div class="title-block row2">
          <div class="text"></div>
          <h2 class="text mt4-8 cloud__title">В любых Направлениях</h2>
        </div>
        <div class="title-block row4">
          <div class="title-block__item"></div>
          <div class="title-block__item">
            <h2 class="cloud__title">
              В любых<br />
              Направлениях
            </h2>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import gsap from "../plugins/gsap.js";
import ScrollTrigger from "gsap/ScrollTrigger";
export default {
  data() {
    return {
      tags1: [
        "Blogging",
        "Content Creation",
        "SMM",
        "Content",
        "Marketing",
        "Copywriting",
        "Outreach",
        "PR",
        "Event",
        "Promo",
        "Reputation",
        "Management",
        "Viral Content",
        "Creation",
        "Viral Content",
        "Marketing",
        "Digital Ad Design",
        "Drone Content",
        "Graphic",
        "Design",
        "Video Content",
      ],
      tags2: [
        "Marketing",
        "Media Buying",
        "Media Planning",
        "Mobile Marketing",
        "Organic Search",
        "Retargeting",
        "SEM",
        "SEO",
        "Social",
        "Community",
        "Growth",
        "Local Marketing",
        "Product",
        "Marketing",
        "Storytelling",
        "Brand",
        "Positioning",
        "Competitor",
        "Analysis",
        "A/B Testing",
      ],
      tags3:
        "Blogging Content Creation SMM Content Marketing Copywriting Outreach PR Event Promo Reputation Management Viral Content Creation Viral Content Marketing Digital Ad Design Drone Content Graphic Design Video Content Digital Targeting Digital Campaign Optimization Display Marketing Facebook Ads Google AdWords Influencer Marketing Media Buying Media Planning Mobile Marketing Organic Search Retargeting SEM SEO Social Community Growth Local Marketing Product Marketing Storytelling Brand Positioning Competitor Analysis A/B Testing Big Data OLV Television Print ads UX UI",
    };
  },
  methods: {
    horizontalScroll() {
      let centerCol = this.$refs.centerCol;
      let item2 = this.$refs.item2;
      // let centerColHeight = this.$refs.centerCol.offsetHeight;
      let rightColWrapper = this.$refs.rightColWrapper;
      let rightColWidth =
        this.$refs.rightCol.offsetWidth - rightColWrapper.offsetWidth;
      let block = this.$refs.scrollSection;

      let scrollWidth =
        window.innerWidth < 767
          ? rightColWidth
          : Math.min(
              rightColWidth,
              this.$refs.leftCol.offsetHeight,
              this.$refs.centerCol.offsetHeight
            );

      if (window.innerWidth < 767) {
        scrollWidth = rightColWidth;
      }

      gsap.to(this.$refs.leftCol, {
        scrollTrigger: {
          scrub: true,
          trigger: block,
          start: "-30px top",
          end: () => `+=${scrollWidth}`,
          invalidateOnRefresh: true,
        },
        y: () => -scrollWidth,
        ease: "none",
      });

      gsap.to(this.$refs.centerCol, {
        scrollTrigger: {
          scrub: true,
          trigger: block,
          start: "-30px top",
          end: () => `+=${scrollWidth}`,

          invalidateOnRefresh: true,
        },
        // y: () => scrollWidth - item2.offsetHeight*2.5,
        y: () => scrollWidth - item2.offsetHeight,

        startAt: {
          y: () => {
            return -centerCol.offsetHeight + item2.offsetHeight;
          },
        },
        ease: "none",
      });

      gsap.to(this.$refs.rightCol, {
        scrollTrigger: {
          scrub: true,
          pin: true,
          anticipatePin: 1,
          trigger: block,
          start: "-30px top",
          end: () => `+=${scrollWidth}`,
          invalidateOnRefresh: true,
        },
        x: () => -scrollWidth,
        ease: "none",
      });
    },
  },
  mounted() {
    this.horizontalScroll();
  },
  updated() {
    ScrollTrigger.refresh(true);
  },
};
</script>
<style lang="scss" scoped>
.section-wrapper {
  background-color: #ffffff;
  // padding-top: 9.25vh;
  // padding-bottom: 9.25vh;
  @media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    display: none;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    display: none;
  }
}
.cloud {
  max-width: 1620px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  background-color: #ffffff;
  min-height: 100vh;

  @media (max-width: 991px) {
    padding: 0 20px;
  }

  &__inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      justify-content: center;
    }
  }

  &__item {
    max-height: 69.4vh;
    display: flex;
    flex-direction: column;
    padding: 20px 15px;
    width: 21.9%;
    background-color: #ffffff;

    border: 2px solid #f0582a;
    overflow-y: hidden;
    overflow-x: hidden;

    &.one,
    &.two {
      overflow-y: hidden;
      @media (max-width: 767px) {
        display: none;
      }
    }

    &.three {
      padding-bottom: 1.5rem;
      @media (max-width: 767px) {
        padding-bottom: 4rem;
        width: 100%;
        max-height: 60vh;
      }
    }

    @media (max-width: 1450px) {
      width: 23%;
    }

    @media (max-width: 1200px) {
      max-height: 65vh;
      width: 32%;
    }
    @media (max-width: 991px) {
      max-height: 50vh;
    }

    @media (max-height: 1400px) and (min-height: 1100px) {
      max-height: 50vh;
    }

    &-inner {
      &.right {
        overflow-x: hidden;
        overflow-y: hidden;
        max-height: 64.8vh;
        background-color: transparent;
      }
    }
  }

  &__text {
    font-size: 2rem;
    line-height: 1.2;

    &--right {
      // ? width: 500%;
      width: 250%;
      overflow: hidden;
      column-width: 500px;
      height: 100%;
      display: inline-block;

      @media (max-width: 767px) {
        font-size: 17px;
        // ? width: 300%;
        width: 195%;
      }

      @media (max-width: 360px) {
        width: 250%;
      }
    }
  }

  &__text-block {
    width: 21.9%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: transparent;

    font-size: 2rem;
    line-height: 1.2;

    @media (max-width: 1450px) {
      width: 23%;
    }

    @media (max-width: 1200px) {
      display: none;
    }
  }

  &__title {
    font-size: 2rem;
    line-height: 1.2;
  }
}

.transform {
  transform: translateY(-50%);
}

.top-block {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.title-block {
  display: none;
  margin-bottom: 4.8vh;
  width: 100%;
  justify-content: end;

  background-color: transparent;

  font-size: 2rem;
  line-height: 1.2;

  &.row3,
  &.row4 {
    display: none;

    @media (max-width: 767px) {
      display: flex;
      width: 100%;
      justify-content: flex-start;
    }
  }

  @media (max-width: 1200px) {
    display: flex;
  }

  @media (max-width: 767px) {
    display: none;
  }

  &.row2 {
    margin-bottom: 0;
    margin-bottom: 4.8vh;
  }

  &.row3 {
    margin-bottom: 2.4vh;
  }

  &__item {
    width: 50%;
    @media (max-width: 767px) {
      margin-top: 2.4vh;
    }
  }
}

.wrapper {
  overflow-y: hidden;
}

.text {
  width: 50%;
}

.mt4-8 {
  margin-top: 2.4vh;
}
</style>
