import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../pages/Home.vue";
import Archive from "../pages/Archive.vue";
import Project from "../pages/Project.vue";
// import ScrollTrigger from 'gsap/ScrollToPlugin';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  {
    path: "/archive",
    name: "Archive",
    component: Archive,
  },

  {
    path: "/project/:id",
    name: "Project",
    component: Project,
  },
];

const router = new VueRouter({
  scrollBehavior(to) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (to.hash) {
          resolve({
            selector: to.hash,
          });
        } else {
          resolve({
            x: 0,
            y: 0,
          });
        }
      }, 500);
    });
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
