<template>
  <div id="project" class="project">
    <div
      class="media-block images"
      v-if="projectFormat == 2"
      :style="{ order: mediaOrder }"
    >
      <div class="item__img" v-for="(item, ind) in images" :key="ind">
        <img :src="item.file" alt="" />
      </div>
    </div>
    <div
      class="media-block"
      v-if="hostedVideos.length > 0"
      :style="{ order: mediaOrder }"
    >
      <div
        class="video-container"
        v-for="(item, ind) in hostedVideos"
        :key="ind"
      >
        <iframe
          :src="item.file"
          muted="true"
          allow="autoplay; fullscreen"
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
    <div
      class="media-block"
      v-if="projectFormat !== 1"
      :style="{ order: textOrder }"
    >
      <div class="item">
        <div class="content">
          <div class="content__top">
            <h2 class="content__title mobile">{{ title }}</h2>
            <div class="content__text mobile">{{ createdAt }}</div>
          </div>
          <div class="content__bottom">
            <div class="content__row" v-if="brief !== ''">
              <h2 class="content__title">Задача</h2>
              <div class="content__text" v-html="brief"></div>
            </div>
            <div class="content__row" v-if="solution !== ''">
              <h2 class="content__title">Решение</h2>
              <div class="content__text" v-html="solution"></div>
            </div>
            <div class="content__row" v-if="result !== ''">
              <h2 class="content__title">Результат</h2>
              <div class="content__text" v-html="result"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      projectFormat: "",
      fileFormat: "",
      createdAt: "",
      brief: "",
      solution: "",
      result: "",
      title: "",
      images: [],
      hostedVideos: [],
      textOrder: 2,
      mediaOrder: 1,
    };
  },
  methods: {
    async getProjectInfo() {
      let id = this.$route.params.id;
      let response = await fetch("/api/project/view?id=" + id);

      if (response.ok) {
        let json = await response.json();
        let project = json.data.project;
        this.projectFormat = project.format;

        this.brief = project.brief;
        this.solution = project.solution;
        this.result = project.result;
        this.title = project.title;

        let files = project.files;
        files.forEach((file) => {
          this.mediaOrder = file.position;
          this.textOrder = file.position == 1 ? 2 : 1;
          if (file.format == 2) {
            this.images.push(file);
          } else {
            this.hostedVideos.push(file);
          }
        });
        this.createdAt = project.created_at.slice(0, 10);
      } else {
        console.log("Ошибка HTTP: " + response.status);
      }
    },
  },

  async mounted() {
    await this.getProjectInfo();
  },
};
</script>
<style lang="scss" scoped>
.project {
  max-width: 1620px;
  margin: 0 auto;
  padding-top: 100px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1280px) {
    padding-top: 75px;
  }
  @media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    display: none;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    display: none;
  }
}

.item {
  padding: 40px 40px 0;
  // margin-top: 40px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 991px) {
    padding: 20px 20px 0;
    // margin-top: 20px;
  }

  @media (max-width: 767px) {
    padding: 10px 10px 0;
    // margin-top: 20px;
  }

  &__img {
    max-width: 1540px;
    height: auto;
    width: 100%;
    // max-height: 710px;
    height: 65vmin;

    @media (max-width: 991px) and (orientation: portrait) {
      max-height: 32vh;
    }

    &:not(:last-child) {
      margin-bottom: 40px;
      @media (max-width: 991px) {
        margin-bottom: 20px;
      }

      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    img {
      // margin: 0 auto;
      width: 100%;
      height: 100%;
      object-fit: cover;

      // display: block;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;

  &__top {
    padding: 40px 0 0 0;
    height: 395px;
    display: flex;
    border-top: 2px solid #f0582a;
    border-bottom: 2px solid #f0582a;
    width: 100%;

    @media (max-width: 991px) {
      padding: 20px 0 0 0;
      height: 150px;
    }

    @media (max-width: 767px) {
      padding: 10px 0 0 0;
      height: 60px;
    }
  }

  &__title {
    width: 31%;
    font-size: 2rem;

    &.mobile {
      @media (max-width: 767px) {
        width: 69%;
      }
    }
  }

  &__text {
    width: 68%;
    font-size: 2rem;
    text-transform: none;
    &.mobile {
      @media (max-width: 767px) {
        width: 31%;
        text-align: end;
      }
    }
  }

  &__bottom {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 140px;

    @media (max-width: 991px) {
      padding-top: 20px;
      padding-bottom: 13px;
    }

    @media (max-width: 767px) {
      padding-top: 10px;
      padding-bottom: 24px;
    }
  }

  &__row {
    display: flex;
    &:not(:last-child) {
      padding-bottom: 40px;

      @media (max-width: 991px) {
        padding-bottom: 20px;
      }

      @media (max-width: 767px) {
        padding-bottom: 10px;
      }
    }
  }
}

.block {
  width: 100%;
  height: 73vh;
  height: 100%;
  position: relative;

  @media (max-width: 1050px) {
    height: 75vh;
  }

  @media (max-width: 991px) {
    // height: 36.4vh;
    height: 43vh;
  }

  @media (max-width: 767px) {
    height: 27vh;
  }

  &:not(:last-child) {
    margin-bottom: 40px;
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
  }
}

.media-block {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.images {
    padding: 0 40px;

    @media (max-width: 991px) {
      padding: 0 20px;
    }

    @media (max-width: 767px) {
      padding: 0 10px;
    }
  }
}

.video-container {
  position: relative;

  padding-bottom: 49.25%;
  width: 100%;

  height: 0;
  // max-height: 790px;
  background-color: #000000;

  overflow: hidden;
  @media (max-width: 1300px) {
    padding-bottom: 37.25%;
  }
  @media (max-width: 1100px) {
    padding-bottom: 56.25%;
  }
  &:not(:last-child) {
    margin-bottom: 40px;
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;

  top: 0;

  left: 0;

  width: 100%;

  height: 100%;
  // max-height: 790px;
}

.video-wrapper {
  width: 100%;
  max-height: 790px;
}
</style>
