<template>
  <div class="wrapper">
    <div class="project-block" ref="projectBlock">
      <div class="project-block__left" ref="scrollBlock">
        <div class="row row1">
          <div v-for="(item, index) in row1" :key="index" class="item">
            <div class="item__inner">
              <div class="item__top" @click="openProject(item.id)">
                <img :src="item.preview" alt="" />
              </div>
              <div class="frame"></div>
            </div>
            <div class="item__text" @click="openProject(item.id)">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="row row2">
          <div class="item" v-for="(item, index) in row2" :key="index">
            <div class="item__inner">
              <div class="item__top" @click="openProject(item.id)">
                <img :src="item.preview" alt="" />
              </div>
              <div class="frame"></div>
            </div>
            <div class="item__text" @click="openProject(item.id)">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="project-block__right one">
        <div class="visibleblock"></div>
        <div class="visibleblock">
          <router-link tag="div" to="/archive" class="link">
            <h2 class="visibleblock__title">
              Еще больше работ<br />
              в архиве
            </h2>
          </router-link>
          <router-link tag="div" to="/archive" class="link mobile">
            <h2 class="visibleblock__title">
              Еще больше<br />
              работ в архиве
            </h2>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gsap from "../plugins/gsap.js";
import ScrollTrigger from "gsap/ScrollTrigger";

export default {
  data() {
    return {
      row1: [],
      row2: [],
    };
  },
  methods: {
    horizontalScroll() {
      let horizontalSection = this.$refs.scrollBlock;
      let block = this.$refs.projectBlock;
      gsap.to(horizontalSection, {
        scrollTrigger: {
          scrub: true,
          trigger: block,
          pin: true,
          anticipatePin: 1,
          start: "-50px top",
          end: () => `+=${horizontalSection.offsetWidth}`,
          invalidateOnRefresh: true,
        },
        x: () =>
          -horizontalSection.offsetWidth +
          window.innerWidth -
          window.innerWidth * 0.2,
        // -horizontalScrollLength + window.innerWidth ,
        ease: "none",
      });
    },

    async getProjects() {
      let response = await fetch("/api/project/index?n=8");

      if (response.ok) {
        let json = await response.json();
        let projects = json.data.projects;
        this.row1 = projects.filter((el, ind) => {
          return ind % 2;
        });
        this.row2 = projects.filter((el, ind) => {
          return !(ind % 2);
        });
      } else {
        console.log("Ошибка HTTP: " + response.status);
      }
    },

    openProject(id) {
      this.$router.push({ name: "Project", params: { id: id } });
    },
  },
  async created() {
    await this.getProjects();
  },
  mounted() {
    this.horizontalScroll();
  },
  updated() {
    ScrollTrigger.refresh(true);
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  background-color: #f0582a;
  padding-top: 9.25vh;
  padding-bottom: 9.25vh;

  @media (max-width: 1050px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.project-block {
  // padding: 149px 0 249px;
  // max-width: 1620px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: #f0582a;
  overflow-x: hidden;

  @media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    display: none;
  }

  @media (max-width: 767px) and (orientation: landscape) {
    display: none;
  }

  &__left {
    background-color: transparent;
    display: flex;
    flex-direction: column;
  }

  &__right {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding-bottom: 11.5vh;
    display: flex;
    width: 25%;
    background-color: #f0582a;

    color: #010001;
    font-size: 2rem;
    line-height: 1.2;

    z-index: 5;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &.one {
      @media (max-width: 767px) {
        background-color: transparent;
        width: 50%;
      }
    }

    &.mobile {
      display: none;
      @media (max-width: 767px) {
        margin-left: 50vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
  }
}

.row {
  padding-top: 2.22vh;
  padding-left: 2.22vh;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin-top: 40px;
  }

  @media (max-width: 767px) {
    margin-top: 0;
  }

  &:not(:last-child) {
    margin-bottom: 2.6vh;
  }
}

.row1 {
  transform: translateX(37vh);

  @media (max-width: 767px) {
    transform: translateX(-2vw);
  }
}

.row2 {
  @media (max-width: 767px) {
    transform: translateX(48vw);
  }
}

.item {
  width: 26.8vh;

  margin-right: 46.2vh;
  cursor: pointer;

  @media (max-width: 767px) {
    margin-right: 47vw;
  }

  &__top {
    position: absolute;
    z-index: 2;
    height: 26.8vh;

    background-color: #010001;
    width: 100%;

    transform: translate(-2.22vh, -2.22vh);

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: 767px) {
      height: 46vw;
      transform: translate(-12px, -12px);
    }
  }

  &__text {
    margin-top: 2.4vh;
    background-color: transparent;

    color: #010001;
    font-size: 1.4rem;

    line-height: 1.2;
    transition: color 0.2s linear;

    @media (max-width: 1280px) {
      font-size: 1.2rem;
    }

    @media (max-width: 1100px) {
      font-size: 2rem;
    }

    @media (max-width: 767px) {
      margin-top: 16px;
    }
  }
}

.item:hover .item__text {
  color: #ffffff;
}
.frame {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 26.8vh;
  border: 1px solid #010001;

  @media (max-width: 767px) {
    height: 46vw;
    width: 46vw;
  }
}

a.link:active,
a.link:hover,
a.link {
  color: #010001;
}
.link {
  background-color: #f0582a;
  width: 100%;
  cursor: pointer;
  transition: color 0.2s linear;
  &:hover {
    color: #ffffff;
  }
  @media screen and (min-width: 0\0) {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &::after {
    margin-left: 10px;
    content: "";
    background-image: url("../assets/images/black_arrow.svg");
    width: 26px;
    height: 26px;
    transition: background-image 0.2s linear;

    @media (max-width: 991px) {
      background-image: url("../assets/images/arrow_black_mid.svg");
      width: 22px;
      height: 23px;
    }

    @media (max-width: 767px) {
      background-image: url("../assets/images/arrow_black_sm.svg");
      width: 16px;
      height: 17px;
    }
  }
}

.link:hover {
  &::after {
    content: "";
    background-image: url("../assets/images/white_arrow.svg");

    @media (max-width: 991px) {
      background-image: url("../assets/images/arrow_white_mid.svg");
    }

    @media (max-width: 767px) {
      background-image: url("../assets/images/arrow_white_sm.svg");
    }
  }
}

.item__inner {
  position: relative;
  height: 26.8vh;

  width: 100%;

  @media (max-width: 767px) {
    height: 46vw;
    width: 46vw;
  }
}

.mobile__link {
  display: flex;
  align-items: flex-end;
  height: 80vw;
  width: 100%;
  background-color: #f0582a;
}

.visibleblock {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  min-height: 50%;
  background-color: #f0582a;

  @media screen and (min-width: 0\0) {
    position: relative;
  }

  @media (max-width: 767px) {
    background-color: transparent;
    position: relative;
  }

  &__title {
    margin-left: 5px;
    font-size: 2rem;
    line-height: 1.2;
  }
}

.link {
  display: flex;
  @media (max-width: 767px) {
    display: none;
  }
  &.mobile {
    display: none;
    @media (max-width: 767px) {
      display: flex;
      position: absolute;
      bottom: -2vh;
    }
  }
}
</style>
