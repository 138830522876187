<template>
  <div id="home">
    <info-block />
    <video-block />
    <project-block />
    <tag-cloud />
    <team />
    <contacts />
  </div>
</template>
<script>
import InfoBlock from "../components/InfoBlock.vue";
import ProjectBlock from "../components/ProjectBlock.vue";
import TagCloud from "../components/TagCloud.vue";
import Team from "../components/Team.vue";
import Contacts from "../components/Сontacts.vue";
import VideoBlock from "../components/VideoBlock.vue";

export default {
  data() {
    return {
      orientation: "",
    };
  },
  components: {
    InfoBlock,
    ProjectBlock,
    TagCloud,
    Team,
    Contacts,
    VideoBlock,
  },

  methods: {
    scrollToElement() {
      let section = this.$router.currentRoute.hash.replace("#", "");
      if (section)
        this.$nextTick(() =>
          window.document
            .getElementById(section)
            .scrollIntoView({ block: "start" })
        );
    },

    checkOrientation() {
      this.orientation =
        window.innerWidth > window.innerHeight ? "landscape" : "portrait";
    },
  },

  mounted() {
    console.log("mounted");
    this.scrollToElement();
    this.checkOrientation();

    window.addEventListener("resize", this.checkOrientation);
  },
  watch: {
    $route() {
      this.scrollToElement();
    },
    orientation(newOrintation, prevOrintation) {
      if (newOrintation == "portrait" && prevOrintation == "landscape") {
        location.reload();
        this.orientation = "portrait";
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
