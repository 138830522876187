<template>
  <section class="wrapper" id="info">
    <div class="video">
      <div class="video__poster">
        <vimeo
          :options="{
            id: 574053386,
            responsive: true,
            background: true,
            muted: true,
            autoplay: true,
            autopause: false,
          }"
        />
      </div>
      <h1 class="video__title">
        Innocean постоянно<br />
        меняется.<br /><span class="orange">меняйтесь</span> с нами
      </h1>
      <div class="video__bottom">
        <router-link to="/#contacts" class="link">go innocean</router-link>
      </div>
    </div>
  </section>
</template>
<script>
import Vimeo from "./Vimeo.vue";
export default {
  components: {
    Vimeo,
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  background-color: #000000;
}
.video {
  // max-width: 1620px;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  @media (min-height: 1100px) and (max-height: 1400px) {
    min-height: 50vh;
  }
  @media (max-width: 991px) {
    min-height: auto;
  }
  @media (max-width: 767px) {
    min-height: 50vh;
  }
  @media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    display: none;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    display: none;
  }
  &__poster {
    width: 100%;
    height: 100vh;

    max-height: 80vh;
    background: url("https://i.vimeocdn.com/video/1187334775-78e20b003d5de6c2c6adba65c8585d72bdb8dc3f0127191cb5e8fb64c604cbb7-d?mw=1500&mh=844")
      no-repeat center center;
    background-size: cover;

    @media (max-width: 1150px) {
      min-height: 100%;
    }
    @media (width: 1024px) and (height: 1366px) and (orientation: portrait) {
      height: 50vh;
    }

    @media (max-width: 991px) {
      height: 42vh;
    }

    @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
      height: 50%;
    }

    @media (max-width: 767px) and (min-height: 737px) {
      height: 50%;
    }

    @media (max-width: 767px) {
      background-color: #000000;
    }
  }

  &__bottom {
    padding: 25px 40px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;

    border-top: 2px solid #f0582a;
    height: 22vh;
    background-color: #000000;

    color: #f0582a;
    font-size: 2rem;
    line-height: 1.2;
    text-transform: uppercase;

    cursor: pointer;

    @media (width: 1366px) and (height: 1024px) and (orientation: landscape) {
      height: 26vh;
    }

    @media (max-width: 1050px) {
      height: 25vh;
    }
    @media (width: 1024px) and (height: 1366px) and (orientation: portrait) {
      height: 20vh;
    }
    @media (max-width: 991px) {
      height: auto;
      border-top: none;
      border-bottom: 2px solid #f0582a;
      background-color: transparent;
    }

    @media (max-width: 767px) {
      padding: 23px 11px 20px;
    }
  }

  &__title {
    position: absolute;
    left: 42px;
    bottom: 22vh;
    font-size: 6.25rem;
    color: #ffffff;

    @media (max-width: 991px) {
      bottom: 50px;
    }

    @media (max-width: 767px) {
      font-size: 25px;
      left: 0;
      right: 0;
      padding-left: 11px;
      padding-bottom: 50px;
      background-color: #000000;
      bottom: 0;
    }

    @media (max-width: 375px) {
      padding: 50px 11px;
    }

    @media (max-width: 374px) {
      padding: 0 11px 50px;
    }
  }
}

a {
  cursor: pointer;
}
a.link:hover {
  cursor: pointer;
}
a.link:active,
a.link {
  color: #f0582a;
}
a.link:hover {
  color: #ffffff;
  transition: color 0.2s linear;
}
.link {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.2s linear;

  &::after {
    margin-left: 10px;
    content: "";
    background-image: url("../assets/images/orange_arrow.svg");
    width: 26px;
    height: 26px;
    transition: background-image 0.2s linear;

    @media (max-width: 991px) {
      background-image: url("../assets/images/arrow_orange_mid.svg");
      width: 22px;
      height: 23px;
    }

    @media (max-width: 767px) {
      background-image: url("../assets/images/arrow_orange_sm.svg");
      width: 16px;
      height: 17px;
    }
  }
}
.link:hover {
  &::after {
    background-image: url("../assets/images/white_arrow.svg");

    @media (max-width: 991px) {
      background-image: url("../assets/images/arrow_white_mid.svg");
    }

    @media (max-width: 767px) {
      background-image: url("../assets/images/arrow_white_sm.svg");
    }
  }
}

.orange {
  color: #f0582a;
}
</style>
