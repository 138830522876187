var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"wrapper",attrs:{"id":"info"}},[_c('div',{staticClass:"video"},[_c('div',{staticClass:"video__poster"},[_c('vimeo',{attrs:{"options":{
          id: 574053386,
          responsive: true,
          background: true,
          muted: true,
          autoplay: true,
          autopause: false,
        }}})],1),_vm._m(0),_c('div',{staticClass:"video__bottom"},[_c('router-link',{staticClass:"link",attrs:{"to":"/#contacts"}},[_vm._v("go innocean")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"video__title"},[_vm._v(" Innocean постоянно"),_c('br'),_vm._v(" меняется."),_c('br'),_c('span',{staticClass:"orange"},[_vm._v("меняйтесь")]),_vm._v(" с нами ")])}]

export { render, staticRenderFns }