<template>
  <div class="wrapper">
    <section class="video-block">
      <hr class="hr-left" />
      <div class="video-block__body">
        <hr class="hr-black top" />
        <div class="video-block__poster">
          <vimeo
            :options="{
              id: 570302624,
              responsive: true,
              autoplay: true,
              loop: true,
              muted: true,
            }"
          />
        </div>
        <hr class="hr-black bottom" />
      </div>
      <div class="video-block__text one">
        <h2 class="video-block__title">
          ваш проект может<br />
          стать частью<br />
          нашего видео
        </h2>
      </div>
      <div class="video-block__text two">
        <h2 class="video-block__title">
          ваш проект<br />
          может стать <br />
          частью нашего <br />
          видео
        </h2>
      </div>
    </section>
  </div>
</template>
<script>
import Vimeo from "./Vimeo.vue";
export default {
  components: {
    Vimeo,
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  background-color: #000000;
  @media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    display: none;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    display: none;
  }
}
.hr-black {
  position: absolute;
  left: 0;
  right: 0;
  height: 5px;
  background-color: #000000;
  z-index: 2;

  &.top {
    top: -2px;

    @media (max-width: 991px) {
      display: none;
    }
  }

  &.bottom {
    bottom: -2px;
  }
}

.hr-left {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 2px;
  left: 1px;
  background-color: #000000;
}
.video-block {
  // max-width: 1620px;
  margin: 0 auto;
  position: relative;
  background-color: #010001;
  min-height: 100vh;

  @media (width: 1024px) and (height: 1366px) and (orientation: portrait) {
    min-height: 50vh;
  }

  @media (max-width: 991px) {
    min-height: 678px;
  }

  @media (max-width: 767px) {
    min-height: 50vh;
  }
  @media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    display: none;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    display: none;
  }

  &__poster {
    background: url("https://i.vimeocdn.com/video/1179304492-4dd092bf10072b865fd9d84a962ea497bd25ed9d5ee6f113d67b936b4b15c375-d?mw=1900&mh=1069")
      no-repeat center center;
    background-size: cover;
  }

  &__body {
    position: relative;
  }

  &__text {
    padding: 40px 40px 40px 0;
    text-align: end;

    &.one {
      display: block;

      @media (max-width: 991px) {
        display: none;
      }

      @media (max-width: 767px) {
        display: block;
        padding: 20px 30px 32px 0;
      }
    }

    &.two {
      display: none;

      @media (max-width: 991px) {
        display: block;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  &__title {
    font-size: 2rem;
    line-height: 1.2;
    color: #f0582a;
  }
}
</style>
