<template>
  <div class="wrapper">
    <my-header></my-header>
    <main>
      <router-view />
      <!-- <home></home> -->
      <div class="message">Пожалуйста, поверните телефон</div>
    </main>
  </div>
</template>
<script>
import MyHeader from "./components/MyHeader.vue";
// import Home from "./pages/Home.vue";
// import LocomotiveScroll from "locomotive-scroll";
// import ScrollTrigger from "gsap/ScrollTrigger";
// import "./assets/styles/locomotive-scroll.scss";

export default {
  data() {
    return {};
  },
  components: {
    MyHeader,
  },
};
</script>

<style lang="scss" scoped>
// @import "@/assets/styles/locomotive-scroll.scss";
.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.message {
  width: 100vw;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: center;
  text-transform: none;

  background-color: #000000;

  font-size: 30px;
  color: #f0582a;
  @media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    display: flex;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    display: flex;
  }
}
</style>
