<template>
  <div class="archive" ref="archiveScroll">
    <div class="archive__inner">
      <div class="archive__col" ref="colBottom">
        <div
          @click="openProject(item.id)"
          v-for="(item, index) in col1"
          :key="index"
          class="product__item"
        >
          <img class="product__img" :src="item.preview" alt="" />
          <div class="product__bottom">
            <hr class="product__hr" />
            <div class="product__title">{{ item.title }}</div>
          </div>
        </div>
      </div>

      <div class="archive__col" ref="colTop">
        <div
          @click="openProject(item.id)"
          v-for="(item, index) in col2"
          :key="index"
          class="product__item"
        >
          <img class="product__img" :src="item.preview" alt="" />
          <div class="product__bottom">
            <hr class="product__hr" />
            <div class="product__title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gsap from "../plugins/gsap.js";
import ScrollTrigger from "gsap/ScrollTrigger";
export default {
  data() {
    return {
      col1: [],
      col2: [],
    };
  },

  methods: {
    onResize() {
      let scrollBottom = this.$refs.colBottom;
      let headerHeight = window.innerWidth < 1280 ? 75 : 100;
      gsap.to(scrollBottom, {
        scrollTrigger: {
          scrub: true,
          invalidateOnRefresh: true,
        },
        y: () => scrollBottom.offsetHeight - window.innerHeight,
        startAt: {
          y: () => {
            return (
              -scrollBottom.offsetHeight - headerHeight + window.innerHeight
            );
          },
        },
        ease: "none",
      });
    },
    async getProjects() {
      let response = await fetch("/api/project/index");

      if (response.ok) {
        let json = await response.json();
        let projects = json.data.projects;
        this.col1 = projects.filter((el, ind) => {
          return ind % 2;
        });
        this.col2 = projects.filter((el, ind) => {
          return !(ind % 2);
        });
      } else {
        console.log("Ошибка HTTP: " + response.status);
      }
    },
    openProject(id) {
      this.$router.push({ name: "Project", params: { id: id } });
    },
  },

  async created() {
    await this.getProjects();
  },

  mounted() {
    this.onResize();
  },

  updated() {
    ScrollTrigger.refresh();
  },
};
</script>
<style lang="scss" scoped>
.archive {
  width: 100%;

  display: flex;

  min-height: 100vh;
  width: 100%;

  background-color: #ffffff;
  overflow-y: hidden;

  @media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    display: none;
  }

  @media (max-width: 767px) and (orientation: landscape) {
    display: none;
  }

  &__inner {
    padding: 0 40px;
    margin: 0 auto;
    margin-top: 100px;
    max-width: 1620px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1366px) {
      max-width: 1200px;
    }

    @media (max-width: 1280px) {
      margin-top: 75px;
      padding: 0 20px;
    }

    @media (max-width: 767px) {
      padding: 0 10px;
    }
  }

  &__col {
    width: 49%;

    @media (max-width: 767px) {
      width: 48%;
    }
  }
}

.product {
  &__item {
    width: 100%;
    height: 850px;
    position: relative;
    // background-color: orange;
    cursor: pointer;

    @media (max-width: 1366px) {
      height: 600px;
    }

    @media (max-width: 991px) {
      height: 400px;
    }

    @media (max-width: 767px) {
      height: 300px;
    }

    @media (max-width: 414px) {
      height: 220px;
    }

    @media (max-width: 375px) {
      height: 180px;
    }

    @media (max-width: 320px) {
      height: 166px;
    }

    &:not(:last-child) {
      margin-bottom: 40px;

      @media (max-width: 1366px) {
        margin-bottom: 20px;
      }

      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
  }

  &__img {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;

    padding: 0 20px 50px;

    @media (max-width: 991px) {
      padding: 0 10px 23px;
    }
    @media (max-width: 767px) {
      padding: 0 5px 13px;
    }
  }
  &__hr {
    width: 100%;
    height: 2px;
    background-color: #f0582a;
    margin-bottom: 23px;

    @media (max-width: 991px) {
      margin-bottom: 10px;
    }

    @media (max-width: 767px) {
      margin-bottom: 5px;
      height: 1px;
    }
  }
  &__title {
    font-size: 2rem;
    color: #ffffff;
  }
}
</style>
